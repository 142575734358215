import { apiClient } from 'api/apiClient/apiClient'
import { useTranslation } from 'react-i18next'
import { UseQueryResult, useMutation, useQuery } from 'react-query'
import { useAlert } from 'ui/components/AlertContext/AlertContext'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { SubtypeObject } from 'ui/components/NotificationCenter/NotificationCenter.helper'

import { queryClient } from 'helpers/queryClient'
import { RecursiveMap } from 'views/OptimizationAnalysisView/types'

export type OptJobType =
  | `regular`
  | `followup_with_deviations`
  | `followup_without_deviations`
  | `followup_regular`
  | `measvalues_calculations`
  | `marginal_cost_calculation`
  | `sandbox`
  | 'real_time'
  | 'real_time_measvalues_calculations'
  | 'fuel_plan'

// The status 'Any' is used when getOptJobLatestKey for useQuery and invalidateQueries when the status is not defined
export type OptJobStatus = `Created` | `Queued` | `Running` | `Failed` | `Finished` | `Unsolvable` | `Any` | undefined

export type OptJobUnitSetting = {
  start_level: number
  end_level: number
}

export type OptJobInputData = {
  opt_start_time: string
  opt_end_time?: string
  unit_settings?: Record<string, OptJobUnitSetting>
  include_subtypes?: string[]
}

type OptJobPostData = {
  opt_model?: number
  opt_job_type: OptJobType
  input_data?: OptJobInputData
}

export type OptJobStatusObject = {
  created_at: string
  id: number
  opt_job_type: OptJobType
  opt_model?: number
  digital_twin?: number
  system?: number
  status: OptJobStatus
  updated_at: string
  subtypes?: SubtypeObject[]
}

type QueueItemResultItem = {
  time: string
  costs: RecursiveMap<string, number>,
  production: RecursiveMap<string, number>,
}

type QueueItemResultSolution = {
  data: QueueItemResultItem[]
  [key: string]: undefined | QueueItemResultItem[]
}

type QueueItemResult = {
  solution: QueueItemResultSolution
  [key: string]: undefined | QueueItemResultSolution
}

type QueueItemResultTuple = [undefined, QueueItemResult]

export type QueueItemResultsResponse = QueueItemResultTuple[]

export const OPT_JOBS_QUERY_KEY = `optJobs`

export function getOptJobLatestKey({
  type,
  status,
  sandboxProjectId,
  systemId,
  limit,
}: {
  type: OptJobType;
  sandboxProjectId?: number;
  systemId?: number;
  status?: OptJobStatus,
  limit?: number;
}) {
  const params = []
  params.push(type)

  if (limit !== undefined) {
    params.push(limit)
  }

  if (status !== undefined) {
    params.push(status)
  } else {
    params.push('Any')
  }

  if (sandboxProjectId !== undefined) {
    params.push(sandboxProjectId)
  }
  if (systemId !== undefined) {
    params.push(systemId)
  }

  return [OPT_JOBS_QUERY_KEY, 'latest', ...params]
}


export function getOptjobAsObject(
  latestOptJob: OptJobStatusObject | OptJobStatusObject[] | [] | undefined,
  index?: number
): OptJobStatusObject | undefined {
  const jobIndex = index ?? 0
  if (latestOptJob) {
    if (Array.isArray(latestOptJob)) {
      if (latestOptJob.length > jobIndex) {
        return latestOptJob[jobIndex]
      } else {
        return undefined
      }
    }
    return latestOptJob
  }
}

export function useLatestOptJobStatus({
  enabled,
  limit,
  sandboxProjectId,
  status,
  type,
}: {
  enabled?: boolean
  limit?: number
  sandboxProjectId?: number
  status?: OptJobStatus
  type: OptJobType
}): UseQueryResult<OptJobStatusObject | OptJobStatusObject[] | []> {
  const { activeSystem } = useAuth()
  return useQuery(
    getOptJobLatestKey({ sandboxProjectId: sandboxProjectId, systemId: activeSystem?.id, type: type, status: status, limit: limit }),
    async () => {

      const queryParams: {
        opt_job_type__name: OptJobType
        system?: number
        limit?: number
        sandbox_project?: number
        status?: OptJobStatus
      } = {
        opt_job_type__name: type,
        limit: limit,
        status,
      }

      if (sandboxProjectId) {
        queryParams.sandbox_project = sandboxProjectId
      } else {
        queryParams.system = activeSystem?.id
      }

      const response = await apiClient<OptJobStatusObject[] | []>(`opt_job/latest_status`, {
        params: queryParams,
      })
      return response ?? []
    },
    {
      enabled: enabled ?? true,
    }
  )
}

export function useOptJobMutation() {
  const { t } = useTranslation()
  const { error } = useAlert()
  const { activeSystem } = useAuth()

  return useMutation(
    (data: OptJobPostData) =>
      apiClient(`opt_jobs`, {
        method: `POST`,
        data: { ...data, system: activeSystem?.id },
      }),
    {
      onMutate: async ({ opt_job_type }: OptJobPostData) => {
        return setOptJobLatestQueryData({
          systemId: activeSystem?.id,
          type: opt_job_type,
          data: { status: `Created` },
        })
      },
      onError: (errors, { opt_job_type }, rollback) => {
        rollback?.()
        error(t(`Something went wrong unfortunately, see error: `) + errors)
        queryClient.invalidateQueries(
          getOptJobLatestKey({
            systemId: activeSystem?.id,
            type: opt_job_type,
          }),
          { refetchInactive: true }
        )
      },
      onSuccess: (_, { opt_job_type }: OptJobPostData) => {
        queryClient.invalidateQueries(
          getOptJobLatestKey({
            systemId: activeSystem?.id,
            type: opt_job_type,
          }),
          { refetchInactive: true }
        )
      },
    }
  )
}

async function setOptJobLatestQueryData({
  systemId,
  type,
  status,
  data: newData,
}: {
  systemId?: number
  type: OptJobType
  status?: OptJobStatus
  data: Partial<OptJobStatusObject>
}) {
  const queryKey = getOptJobLatestKey({ systemId: systemId, type: type, status: status })
  await queryClient.cancelQueries(queryKey)

  const previousOptJob = queryClient.getQueryData<OptJobStatusObject | null>(queryKey, { exact: true })

  queryClient.setQueryData<Partial<OptJobStatusObject> | null | undefined>(queryKey, (data) => {
    if (data == null) return { status: newData.status }
    return { ...data, ...newData }
  })

  return () => queryClient.setQueryData(queryKey, previousOptJob)
}

export function useRecentOptJobs({
  status,
  fromDate,
  toDate,
  limit = 25,
}: {
  status: OptJobStatus
  fromDate?: string
  toDate?: string
  limit?: number
}) {
  const { activeSystem } = useAuth()
  return useQuery<OptJobStatusObject[]>([
    OPT_JOBS_QUERY_KEY,
    'recent',
    activeSystem?.id,
    status,
    fromDate,
    toDate,
    limit,
  ], async () => {
    const response = await apiClient<OptJobStatusObject[]>(`systems/${activeSystem?.id}/opt_jobs`, {
      params: {
        status,
        created_from: fromDate,
        created_to: toDate,
        limit,
      },
    })
    return response
  })
}

export function useQueueItemResults({
  optJobId,
}: {
  optJobId?: number
}) : UseQueryResult<QueueItemResultsResponse> {
  return useQuery([
    OPT_JOBS_QUERY_KEY,
    'queueItems',
    'results',
    optJobId,
  ], async () => {
    const response = await apiClient<QueueItemResultsResponse>(`opt_jobs/${optJobId}/queue_item_results`)
    return response
  }, {
    enabled: !!optJobId,
  })
}