import { calculateCalcDatasets, getDatasets } from 'api/dataset/dataset.api'
import i18n from 'i18next'
import moment from 'moment'
import authStore from 'store/auth/auth'
import elplanStore from 'store/elplan/elplan'
import filterStore from 'store/filter/filter'
import uiConfigStore from 'store/uiConfig/uiConfig'
import { ChartItem } from 'ui/uiConfig/components/Chart/chartTypes'
import { DatasetExports, ExcelButtonType } from 'ui/uiConfig/components/ExcelButton/ExcelButton'
import { parseWildcardVariables } from 'ui/uiConfig/components/wildcardItems.helper'
import Datetime from 'utils/datetime/datetime'
import sentry from 'utils/sentry/sentry'
import { snapshot } from 'valtio'

import { getValidISODateTime } from 'helpers/dateTime.helper/dateTime.helper'
import { getFileName, getChosenDateFileName } from 'helpers/export.helper'
import { applyToNestedString, clone, isArray, isNumber, isObject, round } from 'helpers/global.helper/global.helper'
import { getRoute } from 'helpers/route.helper/route.helper'
import { getUiconfigUidFromId } from 'helpers/uiConfig.helper/uiConfig.helper'

function getDatasetWithShortestValueLengthInDatasetInstruction(
  datasets: Dataset[],
  datasetInstruction: DatasetInstruction
): Dataset {
  const contract = datasetInstruction.contract as DatasetContractCalc
  const variables = new Set(contract.variables)
  const variablesDatasets = datasets.filter((dataset) => variables.has(dataset.return_id))
  let shortest = variablesDatasets[0]

  variablesDatasets.forEach((dataset) => {
    if (dataset.values.length < shortest.values.length) {
      shortest = dataset
    }
  })

  return shortest
}

export function hasSources(contract?: DatasetContract): contract is DatasetContractWithSources {
  if (!contract) {
    return false
  }
  return (<DatasetContractWithSources>contract).sources !== undefined
}

export function calcDataset(datasets: Dataset[], datasetInstruction: DatasetInstruction): Dataset {
  const contract = datasetInstruction.contract as DatasetContractCalc
  const operator = contract.operator ?? `+`
  const accumulate = contract.accumulate
  const elementWise = contract.element_wise ?? !!accumulate
  const ifNan = contract.if_nan

  // Nested calc-steps for more complex (but still simple) calculations
  if (contract?.steps?.length && contract.steps.length > 0) {
    for (let i = 0; i < contract.steps.length; i++) {
      const step = contract.steps[i]
      const stepDSInstruction: DatasetInstruction = clone(datasetInstruction)
      stepDSInstruction.return_id = step.return_id
      stepDSInstruction.contract = {
        ...stepDSInstruction.contract,
        steps: undefined,
        ...step,
      }

      const stepDataset = calcDataset(datasets, stepDSInstruction)
      datasets.push(stepDataset)
    }

    // Steps-calc-datasets always returns dataset from the last step
    const lastDataset = datasets[datasets.length - 1]
    lastDataset.return_id = datasetInstruction.return_id
    return lastDataset
  }

  const datasetWithShortestValueLength = getDatasetWithShortestValueLengthInDatasetInstruction(
    datasets,
    datasetInstruction
  )
  const nrOfEntries = elementWise ? (datasetWithShortestValueLength?.values?.length ?? 0) : 1
  let times = datasetWithShortestValueLength?.times?.map((time) => time) ?? []
  let quantity = 0
  let accumulatedValue = 0

  if (!elementWise && times.length) {
    times = [times[times.length - 1]]
  }

  const datasetsByReturnId = getDatasetsByReturnId(datasets, datasetInstruction)

  let dataset: Dataset = {
    return_id: datasetInstruction.return_id,
    times,
    values: [],
  }

  for (let i = 0; i < nrOfEntries; i++) {
    let baseValue = null
    let isFirstEntry = true

    for (const returnId of contract.variables) {
      const value =
        typeof returnId === `number`
          ? returnId
          : datasetsByReturnId[returnId]
            ? datasetsByReturnId[returnId].values[i]
            : 0 //Datasets that does not exists in a calc will be 0

      if (isFirstEntry) {
        baseValue = value
        isFirstEntry = false
        continue
      }

      if (value === null || value === undefined || typeof value !== `number`) {
        if (operator === `if`) {
          baseValue = null
        }
        continue
      }

      if (baseValue === null && operator === `+`) {
        baseValue = value
        continue
      }

      if (operator === 'ifnot') {
        if (baseValue === null) {
          baseValue = value
        }
      }

      if (baseValue === null) {
        continue
      }
      if (operator === `+`) {
        baseValue += value
      } else if (operator === `-`) {
        baseValue -= value
      } else if (operator === `/`) {
        baseValue /= value
      } else if (operator === `*`) {
        baseValue *= value
      } else if (operator === `min`) {
        baseValue = Math.min(baseValue, value)
      } else if (operator === `max`) {
        baseValue = Math.max(baseValue, value)
      } else if (operator === `if`) {
        if (!value) {
          baseValue = 0
        } else {
          baseValue = value
        }
      }
    }

    if (accumulate && baseValue !== null) {
      quantity++
      accumulatedValue += baseValue

      if (accumulate === `mean`) {
        baseValue = accumulatedValue / quantity
      } else if (accumulate === `sum`) {
        baseValue = accumulatedValue
      }
    }

    // Fallback to value of ifNan if the base value is NaN or Infinity
    if (ifNan !== undefined && baseValue !== null) {
      if (isNaN(baseValue) || !isFinite(baseValue)) {
        baseValue = ifNan
      }
    }

    dataset.values.push(baseValue)
  }

  if (elementWise && datasetInstruction.filter.aggregate && !datasetInstruction.filter.aggregate_in_frontend) {
    dataset = aggregateDataset(clone(dataset), datasetInstruction.filter.aggregate)
  }

  if (datasetInstruction.filter.absolute_values) {
    dataset = setAbsoluteValues(dataset)
  }

  return dataset
}

function getDatasetsByReturnId(datasets: Dataset[], datasetInstruction: DatasetInstruction): Record<string, Dataset> {
  const contract = datasetInstruction.contract as DatasetContractCalc
  const elementWise = contract.element_wise ?? !!contract.accumulate

  const datasetsByReturnId: Record<string, Dataset> = datasets.reduce((acc, dataset) => {
    if (!elementWise) {
      return {
        ...acc,
        [dataset.return_id]: aggregateDataset(clone(dataset), datasetInstruction.filter.aggregate || `sum`),
      }
    }

    return {
      ...acc,
      [dataset.return_id]: dataset,
    }
  }, {})

  return datasetsByReturnId
}

function setAbsoluteValues<T extends Pick<Dataset, 'values'>>(dataset: T): T {
  const values = clone(dataset.values)
  return {
    ...clone(dataset),
    values: values.map((value) => (value ? Math.abs(value) : value)),
  }
}

export function getEarliestStartTime(
  datasetInstructions: Partial<DatasetInstruction>[]
): `${number}-${number}-${number}T${number}:${number}:${number}+00:00` {
  return datasetInstructions.reduce(
    (t, datasetInstruction) => {
      const dt = getValidISODateTime(datasetInstruction.filter?.start_time)

      dt.addHours(datasetInstruction.filter?.offset_start_time ?? 0)

      return dt.isBefore(t) ? dt.ISOString : t
    },
    Datetime.toISOString(new Date(`3000-01-01T00:00:00+00:00`))
  )
}

export function getLatestStartTime(
  datasetInstructions: Partial<DatasetInstruction>[]
): `${number}-${number}-${number}T${number}:${number}:${number}+00:00` {
  return datasetInstructions.reduce(
    (t, datasetInstruction) => {
      const dt = getValidISODateTime(datasetInstruction.filter?.end_time)

      dt.addHours(datasetInstruction.filter?.offset_end_time ?? 0)
      return dt.isAfter(t) ? dt.ISOString : t
    },
    Datetime.toISOString(new Date(`1970-01-01T00:00:00+00:00`))
  )
}

export function aggregateDataset(
  dataset: Dataset,
  aggregate: DatasetInstructionAggregate,
  invalidValueIndeces: Set<number> = new Set()
): Dataset {
  if (!aggregate) {
    return dataset
  }

  let aggregatedValue: number | null = null
  const values = dataset.values.filter(
    (value, index) =>
      !invalidValueIndeces.has(index) && value !== null && value !== undefined && typeof value === `number`
  )

  if (!values.length) {
    return {
      ...dataset,
      values: [],
      times: [],
    }
  }

  const nonNullValues = values.filter(isNumber)

  if (aggregate === `sum`) {
    aggregatedValue = nonNullValues.reduce((acc, value) => acc + value, 0)
  } else if (aggregate === `mean`) {
    aggregatedValue = nonNullValues.reduce((acc, value) => acc + value, 0) / nonNullValues.length
  } else if (aggregate === `min`) {
    aggregatedValue = Math.min(...nonNullValues)
  } else if (aggregate === `max`) {
    aggregatedValue = Math.max(...nonNullValues)
  } else if (aggregate === `latest`) {
    aggregatedValue = nonNullValues[nonNullValues.length - 1]
  }

  if (aggregatedValue === null) {
    return {
      ...dataset,
      values: [],
      times: [],
    }
  }

  return {
    ...dataset,
    values: [aggregatedValue],
    times: [dataset.times[values.length - 1]],
  }
}

export function limitDataset<T extends Pick<Dataset, 'values'>>(dataset: T, filter: DatasetInstructionFilter): T {
  const minValue = filter.limit_min
  const maxValue = filter.limit_max

  function limitValue(value: number | null, minValue?: number, maxValue?: number): number | null {
    if (typeof value !== `number`) {
      return value
    }

    if (typeof minValue === `number` && value < minValue) {
      return minValue
    }

    if (typeof maxValue === `number` && value > maxValue) {
      return maxValue
    }

    return value
  }

  return {
    ...clone(dataset),
    values: dataset.values.map((v) => limitValue(v, minValue, maxValue)),
  }
}

export function addOneZeroValueToEndOfDataset<T extends Pick<Dataset, 'values'>>(dataset: T): T {
  const values = clone(dataset.values)
  const realValues = clone(dataset.values)

  for (let i = 0; i < values.length; i++) {
    const v = realValues[i]
    const v_prev = i > 0 ? realValues[i - 1] : null
    const v_next = i < realValues.length - 1 ? realValues[i + 1] : null

    if (v_prev && !v && !v_next) {
      values[i] = 0
    }
  }

  return {
    ...clone(dataset),
    values,
  }
}

export function setNanIfZero<T extends Pick<Dataset, 'values'>>(dataset: T, filter: DatasetInstructionFilter): T {
  if (filter?.zero_is_nan !== true) {
    return dataset
  }

  const values = clone(dataset.values)

  for (let i = 0; i < values.length; i++) {
    const v = values[i]
    const v_prev = i > 0 ? values[i - 1] : null
    const v_next = i < values.length - 1 ? values[i + 1] : null
    if (v !== 0) {
      continue
    }
    if (filter?.skip_previous_zeros && filter?.zero_is_nan && !v_prev) {
      values[i] = null
    } else if (!v_prev && !v_next) {
      values[i] = null
    }
  }

  return {
    ...clone(dataset),
    values,
  }
}

export function getDatasetsInItems(items: ChartItem[], datasets: Dataset[]): Dataset[] {
  const itemDataIds = new Set(items.map((item) => item.data_id))
  const datasetIds = new Set(datasets.map((dataset) => dataset.return_id))

  const datasetreturnIdToDatasetMap: Record<string, Dataset> = datasets.reduce((acc, dataset) => {
    return {
      ...acc,
      [dataset.return_id]: dataset,
    }
  }, {})

  const itemIdToDecimalsMap: Record<string, number> = items.reduce((acc, item) => {
    return {
      ...acc,
      [item.data_id]: item.decimals,
    }
  }, {})

  const datasetsInItems = [
    // Datasets that are in items, in correct order
    ...items
      .filter((item) => datasetIds.has(item.data_id))
      .map((item) => ({ ...datasetreturnIdToDatasetMap[item.data_id], decimal: itemIdToDecimalsMap[item.data_id] })),

    // Complement with datasets that have no items
    ...datasets.filter((dataset) => !itemDataIds.has(dataset.return_id)),
  ]

  return datasetsInItems
}

export function filterDatasetInstructionsForReturnId(
  datasetInstructions: DatasetInstruction[],
  returnId: string,
  options: { removeExtraSources?: boolean } = {}
): DatasetInstruction[] {
  const targetDatasetInstruction = clone(
    datasetInstructions.find((di) => {
      const returnIds = getReturnIdsFromDatasetInstruction(di)
      return new Set(returnIds).has(returnId)
    })
  )

  if (!targetDatasetInstruction) {
    return []
  }

  if (targetDatasetInstruction.type !== `calc`) {
    if (options.removeExtraSources && targetDatasetInstruction.contract?.sources?.length) {
      targetDatasetInstruction.contract.sources = (
        targetDatasetInstruction.contract as DatasetContractOptResults
      ).sources.filter(({ return_id }) => return_id === returnId)
    }

    return [targetDatasetInstruction]
  }

  const returnIdToCalcVariables: Record<string, string[]> = {}
  const allReturnIds = new Set(getReturnIdsFromDatasetsInstruction(datasetInstructions))

  datasetInstructions
    .filter(({ type }) => type === `calc`)
    .forEach((instruction) => {
      (instruction.contract as DatasetContractCalc)?.variables?.forEach((variable) => {
        if (typeof variable === `string` && allReturnIds.has(variable)) {
          if (!returnIdToCalcVariables[instruction.return_id]) {
            returnIdToCalcVariables[instruction.return_id] = []
          }

          returnIdToCalcVariables[instruction.return_id].push(variable)
        }
      })
    })

  const maxIterations = 1000
  let counter = 0
  const getReturnIds = (returnId: string): string[] => {
    counter++

    if (counter >= maxIterations) {
      // eslint-disable-next-line no-console
      console.warn(`Max iterations reached`)
      return []
    }
    if (!returnIdToCalcVariables[returnId]) {
      return [returnId]
    }

    return [returnId, ...returnIdToCalcVariables[returnId].flatMap(getReturnIds)]
  }

  const allRequiredReturnIds = new Set(getReturnIds(targetDatasetInstruction.return_id))

  return datasetInstructions.filter((instruction) => {
    const returnIds = getReturnIdsFromDatasetInstruction(instruction)
    for (const returnId of returnIds) {
      if (allRequiredReturnIds.has(returnId)) {
        return true
      }
    }

    return false
  })
}

export function setSmallValuesToZero(datasets: Dataset[], limitValue: number): Dataset[] {
  datasets.forEach((dataset) => {
    dataset.values = dataset.values.map((v) => (v !== null && Math.abs(v) < limitValue ? 0 : v))
  })

  return datasets
}

export function mapReturnIdtoVariables(datasetInstructions: Partial<DatasetInstruction>[]): Record<string, Set<string>> {
  return datasetInstructions
    .filter(({ type }) => type === `calc`)
    .reduce<Record<string, Set<string>>>((acc, { return_id, contract }) => {
      if (!return_id || !contract) {
        return acc
      }

      const variables = contract?.variables?.filter((variable) => typeof variable === `string`)
      return {
        ...acc,
        [return_id]: new Set(variables),
      }
    }, {})
}

function mapReturnIdToDataset(datasetInstructions: Partial<DatasetInstruction>[]): Record<string, Partial<DatasetInstruction>> {
  return datasetInstructions
    .filter(({ type }) => type === `calc`)
    .reduce<Record<string, Partial<DatasetInstruction>>>((acc, datasetInstruction) => {
      if (!datasetInstruction.return_id) {
        return acc
      }

      return {
        ...acc,
        [datasetInstruction.return_id]: datasetInstruction,
      }
    }, {})
}

export function getMissingCalcVariables(
  datasetInstructions: Partial<DatasetInstruction>[],
  returnIdToVariables: Record<string, Set<string>>
): Set<string> {
  const allReturnIds = new Set(
    datasetInstructions.map((datasetInstruction) => getReturnIdsFromDatasetInstruction(datasetInstruction)).flat()
  )
  const allVariables = new Set(
    Object.values(returnIdToVariables)
      .map((variables) => [...Array.from(variables)])
      .flat()
  )
  const missingVariables: Set<string> = new Set()

  allVariables.forEach((variable) => {
    if (!allReturnIds.has(variable)) {
      missingVariables.add(variable)
    }
  })

  return missingVariables
}

function removeDeletedIds(
  idToVariables: Record<string, Set<string>>,
  deletedIds: Set<string>
): Record<string, Set<string>> {
  return Object.entries(idToVariables).reduce((acc, [returnId, variables]) => {
    return {
      ...acc,
      [returnId]: new Set([...Array.from(variables)].filter((variable) => !deletedIds.has(variable))),
    }
  }, {})
}

function findLoop(returnId: string, path: string[], returnIdToVariables: Record<string, Set<string>>): string[] | null {
  if (path.includes(returnId)) {
    return path
  }

  const variables = returnIdToVariables[returnId]

  if (!variables) {
    return null
  }
  let loop = null

  variables.forEach((variable) => {
    const newPath = [...path, returnId]
    loop = findLoop(variable, newPath, returnIdToVariables)
  })

  return loop
}

export function getLoopedVariableDependencies(
  returnIdToVariables: Record<string, Set<string>>,
  deletedIds: Set<string>
): string[] {
  const loops = []
  returnIdToVariables = removeDeletedIds(returnIdToVariables, deletedIds)
  for (const returnId of Object.keys(returnIdToVariables)) {
    const loop = findLoop(returnId, [], returnIdToVariables)
    if (loop) {
      loops.push(returnId)
    }
  }
  return loops
}

export function getCalcDatasetInstructionOrder(
  datasetInstructions: DatasetInstruction[],
  datasetsWithoutCalc: Dataset[]
): DatasetInstruction[] {
  // Parse wildcard variables, this means that if a variable is a string with a * it will be expanded to multiple variables
  datasetInstructions
    .filter((datasetInstruction) => datasetInstruction.type === `calc`)
    .forEach((datasetInstruction) => {
      datasetInstruction.contract.variables = parseWildcardVariables(datasetInstruction.contract.variables)
    })

  const returnIdToVariables = mapReturnIdtoVariables(datasetInstructions)
  const returnIdToDataset = mapReturnIdToDataset(datasetInstructions)

  // Check if any dataset variable is missing
  const missingVariables = getMissingCalcVariables(datasetInstructions, returnIdToVariables)
  if (missingVariables.size) {
    sentry.captureMessage(`Calc dataset has source-less variables: ${Array.from(missingVariables).join(', ')}`)
  }

  const updateOrder = []
  // deletedIds is a set of return_ids that are resolved, when a return_id is resolved it is added to the set
  const deletedIds = new Set(datasetsWithoutCalc.map(({ return_id }) => return_id))

  // Missing variables are added to the deletedIds set to avoid infinite loops
  missingVariables.forEach((variable) => {
    deletedIds.add(variable)
  })

  // Find looping return ids, we should probalby do something with this but most likelly it's an error in the UIconfig.
  const loopingReturnIds = getLoopedVariableDependencies(returnIdToVariables, deletedIds)

  let counter = 0
  const maxCounter = 1000

  while (Object.keys(returnIdToVariables).length) {
    counter++
    if (counter > maxCounter) {
      sentry.captureMessage(`Infinite loop in getCalcDatasetInstructionOrder`)
      break
    }

    for (const returnId of Object.keys(returnIdToVariables)) {
      const variables = new Set(
        Array.from(returnIdToVariables[returnId]).filter((variable) => !deletedIds.has(variable))
      )

      if (variables.size) {
        returnIdToVariables[returnId] = variables
      } else {
        deletedIds.add(returnId)
        delete returnIdToVariables[returnId]
        updateOrder.push(returnId)
      }
    }
  }

  return updateOrder.map((returnId) => returnIdToDataset[returnId])
}

export function serializeDatasetInstruction(datasetInstruction: DatasetInstruction): DatasetInstruction {
  let serializedContract = datasetInstruction.contract
  const type = datasetInstruction.type

  if (type === `calc`) {
    const contract = datasetInstruction.contract as DatasetContractCalc
    serializedContract = {
      variables: contract.variables || [],
      operator: contract.operator || `+`,
      element_wise: contract.element_wise ?? true,
    }
  } else if (type === `meas`) {
    const contract = datasetInstruction.contract as DatasetContractMeas
    serializedContract = {
      locality: contract.locality ?? ``,
      entry_identifier_prefix: contract.entry_identifier_prefix ?? `meas_raw_`,
      tag: contract.tag ?? ``,
    }
  } else if (type === `optresults`) {
    const contract = datasetInstruction.contract as DatasetContractOptResults
    serializedContract = {
      sources: contract.sources || [],
      opt_model_id: contract.opt_model_id || 0,
      opt_job_type_id: contract.opt_job_type_id ?? 2,
      subtype: contract.subtype ?? ``,
      opt_time: `$opt_time`,
    }

    if (serializedContract.subtype === ``) {
      delete serializedContract.subtype
    }
  } else if (type === 'dbs') {
    const contract = datasetInstruction.contract as DatasetContractDbS
    serializedContract = {
      metadata_filter: contract.metadata_filter || {
        include: {},
        exclude: {},
      },
      id_renaming: contract.id_renaming || {},
    }

    if (contract.global_read_api) {
      serializedContract.global_read_api = true
    }
  }

  datasetInstruction.contract = serializedContract

  return datasetInstruction
}

export async function exportTabsToExcel(
  tabs: ExcelButtonType[],
  propTimeFormat?: string,
  chosenDate?: ISODateTime
): Promise<void> {
  const XLSX = await import(`xlsx`)
  const exportData = XLSX.utils.book_new()

  tabs.forEach((tab, index) => {
    const currentDatasetNames = tab.datasets.map((dataset) => {
      return dataset.title ?? index
    })

    let title = tab.title
    if (title.startsWith('__')) {
      title = i18n.t(title.split('__')[1])
    }

    const customTimeFormat = tab.time_format !== undefined ? tab.time_format : propTimeFormat
    const currentTabData = prepareDatasetsToExcelExportData(
      tab.datasets,
      currentDatasetNames,
      customTimeFormat,
      tab.show_times_in_utc
    )

    XLSX.utils.book_append_sheet(exportData, XLSX.utils.aoa_to_sheet(currentTabData), title)
  })

  if (tabs.length === 0) {
    throw new Error(`Empty workbook`)
  }

  const filename = chosenDate ? getChosenDateFileName(`xlsx`, chosenDate) : getFileName(`xlsx`)

  XLSX.writeFile(exportData, filename, { sheetStubs: true })
}

type FetchUiConfigDatasetsAndExportToExcel = {
  tabs: DatasetExports[]
  datasetStartTime: ISODateTime
  datasetEndTime: ISODateTime
  datasetCreatedAt?: string
  datasetCreatedAtOperator?: DatasetCreatedAtOperators
  time_format: string
}
export async function fetchUiConfigDatasetsAndExportToExcel({
  tabs,
  datasetStartTime,
  datasetEndTime,
  datasetCreatedAt,
  datasetCreatedAtOperator,
  time_format,
}: FetchUiConfigDatasetsAndExportToExcel): Promise<void> {
  const uiConfigSnap = snapshot(uiConfigStore)
  const tabsList: ExcelButtonType[] = []

  for (let i = 0; i < tabs.length; i++) {
    const uid = getUiconfigUidFromId(uiConfigSnap.uiConfigs, tabs[i].ui_config_id)

    if (!uid) {
      continue
    }

    const uiConfig = uiConfigSnap.getParsedUiConfig(uid, tabs[i].overrideAlias, {
      overrideProps: tabs[i].overrideProps,
    })

    if (!uiConfig) {
      continue
    }

    const items = uiConfig?.props.items
    const datasetInstructions = uiConfig ? (uiConfig?.dataset_instructions ?? []) : []
    const arrayWithoutExcludedInstructions = clone(datasetInstructions)
    const onlyReturnIds = getReturnIdsFromDatasetsInstruction(datasetInstructions)

    const datasets = await getDatasets(
      uiConfig.id,
      uiConfig.version,
      arrayWithoutExcludedInstructions,
      datasetStartTime,
      datasetEndTime,
      {
        datasetCreatedAt,
        datasetCreatedAtOperator,
        overrideAlias: tabs[i].overrideAlias,
        onlyReturnIds,
      }
    )
    const calculatedDatasets = calculateCalcDatasets(arrayWithoutExcludedInstructions, datasets)

    if (datasets.length === 0 || !datasets) {
      throw new Error(i18n.t(`No data found`))
    }

    const datasetsUsedInItems: Dataset[] = []
    items?.forEach((item) => {
      //loop on items to make sure items decide the order of the excel export
      const datasetThatExistsInItem = calculatedDatasets.find((dataset) => dataset.return_id === item.data_id)
      if (datasetThatExistsInItem) {
        datasetsUsedInItems.push(datasetThatExistsInItem)
      }
    })

    datasetsUsedInItems.forEach((dataset, index) => {
      const uiConfigItem = uiConfig?.props?.items?.find((item) => item.data_id === dataset.return_id)
      let title: string = uiConfigItem?.title || dataset.return_id

      //if two datasets have the same title you won't be able to distinguish them in export
      const sameNameDataset = datasetsUsedInItems.find((dataset) => dataset.title === title)
      if (sameNameDataset) {
        const newNameForOtherDataset = sameNameDataset.return_id.split('.')[0] ?? index
        sameNameDataset.title = `${title}_${newNameForOtherDataset}`

        const newNameForThisDataset = dataset.return_id.split('.')[0] ?? index
        title = `${title}_${newNameForThisDataset}`
      }
      datasetsUsedInItems[index].title = title
    })

    const excelTabObject = {
      title: tabs[i].title,
      ui_config_id: tabs[i].ui_config_id,
      time_format: tabs[i].time_format,
      show_times_in_utc: tabs[i].show_times_in_utc,
      dataset_instructions: datasetInstructions,
      datasets: datasetsUsedInItems,
    }

    if (datasetInstructions.length !== 0) {
      tabsList.push(excelTabObject)
    }
  }

  exportTabsToExcel(tabsList, time_format, datasetStartTime)
}

export async function exportToExcel(datasets: Dataset[], datasetNames: Record<string, string>): Promise<void> {
  const XLSX = await import(`xlsx`)
  const exportData = XLSX.utils.book_new()

  const worksheetData = prepareDatasetsToExcelExportData(datasets, datasetNames, `YYYY-MM-DD HH:mm`)

  XLSX.utils.book_append_sheet(exportData, XLSX.utils.aoa_to_sheet(worksheetData))
  XLSX.writeFile(exportData, getFileName(`xlsx`), { sheetStubs: true })
}

export async function exportPlannedElectricityProductionToExcel(
  datasets: Dataset[],
  datasetNames: Record<string, string>,
  date?: { startTime: ISODateTime; endTime: ISODateTime }
): Promise<void> {
  const XLSX = await import('xlsx')
  const exportData = XLSX.utils.book_new()
  const authSnap = snapshot(authStore)
  const elplanSnap = snapshot(elplanStore)

  const title = `${authSnap.activeSystem?.display_name}`
  const subtitle = i18n.t('Total planned production')
  const year = Datetime.toLocalTime(date?.startTime ?? elplanSnap.date.start_time, 'year')
  const weekNumber = Datetime.getWeekNumber(date?.startTime ?? elplanSnap.date.start_time)
  const unit = 'MWh/h'
  const dayOfWeek = Datetime.toLocalTime(date?.startTime ?? elplanSnap.date.start_time, 'shortDayText')

  const datasetsWithVolumes = datasets.filter((dataset) => dataset.return_id.includes('volume'))
  const datasetsWithVolumesBlockBids = datasets.filter(
    (dataset) => dataset.return_id.includes('volume') && dataset.return_id.includes('blockbid')
  )
  const datasetsWithVolumesProfileBids = datasets.filter(
    (dataset) => dataset.return_id.includes('volume') && dataset.return_id.includes('profilebid')
  )

  const datasetsWithPricesBlockbid = datasets.filter(
    (dataset) => dataset.return_id.includes('price') && dataset.return_id.includes('blockbid')
  )
  const datasetsWithPricesProfilebid = datasets.filter(
    (dataset) => dataset.return_id.includes('price') && dataset.return_id.includes('profilebid')
  )

  //Sort the bids to be in the correct order
  const extractNumber = (return_id: string): number => {
    const match = return_id.match(/\d+/)
    return match ? parseInt(match[0], 10) : 0
  }

  const sortedDatasetsWithPricesBlockbid = datasetsWithPricesBlockbid.sort(
    (a, b) => extractNumber(a.return_id) - extractNumber(b.return_id)
  )
  const sortedDatasetsWithPricesProfilebid = datasetsWithPricesProfilebid.sort(
    (a, b) => extractNumber(a.return_id) - extractNumber(b.return_id)
  )

  const getPricesFromBlockbids = sortedDatasetsWithPricesBlockbid.map((dataset) =>
    dataset.values.find((value) => value !== null)
  )
  const getPricesFromProfilebids = sortedDatasetsWithPricesProfilebid.map((dataset) =>
    dataset.values.find((value) => value !== null)
  )

  let worksheetDataInfo: (string | number | undefined)[][] = []

  worksheetDataInfo = [
    [title],
    [subtitle],
    [i18n.t('Year'), i18n.t('Week'), i18n.t('Date')],
    [year, weekNumber, Datetime.toLocalTime(date?.startTime ?? elplanSnap.date.start_time, 'onlyDate')],
    [dayOfWeek],
    [unit],
  ]

  worksheetDataInfo.push(['']) // Adds empty row

  const getProfileBidDataInfo = (): string[] => {
    const profileBidDataInfo = []
    for (let i = 0; i < datasetsWithVolumesProfileBids.length; i++) {
      profileBidDataInfo.push(`${i18n.t('Profile bid')} ${i + 1}`)
    }
    return profileBidDataInfo
  }

  const profileBidDataInfo = getProfileBidDataInfo()

  worksheetDataInfo.push([
    '',
    `${i18n.t('Avg price')} (${elplanSnap.currency}/MWh)`,
    '',
    '',
    `${i18n.t('Avg price')} (${elplanSnap.currency}/MWh)`,
    '',
  ])

  const datasetsWithVolumesBlockBidsWithoutNullValues = datasetsWithVolumesBlockBids.filter((dataset) => {
    return !(Array.isArray(dataset.values) && dataset.values.every((value) => value === null))
  }) // TODO: This check can be removed when this wrike is completed: https://app-eu.wrike.com/open.htm?id=1466970642. This is a temporary fix to avoid null values since hardcoded blockbids are still set in templateUiConfigs.ts. When the hardcoded blockbids are removed this check can be removed and datasetsWithVolumesBlockBidsWithoutNullValues should be replaced with datasetsWithVolumesBlockBids.

  for (
    let i = 0;
    i < Math.max(datasetsWithVolumesBlockBidsWithoutNullValues.length, datasetsWithVolumesProfileBids.length);
    i++
  ) {
    worksheetDataInfo.push([
      getPricesFromBlockbids[i] ? `${i18n.t('Block bid')} ${i + 1}` : '',
      getPricesFromBlockbids[i] ?? '',
      '',
      profileBidDataInfo[i] ?? '',
      getPricesFromProfilebids[i] ?? '',
    ])
  }

  worksheetDataInfo.push([''])

  const sortedDatasetsWithVolumes = datasetsWithVolumes.sort(
    (a, b) => extractNumber(a.return_id) - extractNumber(b.return_id)
  )

  const datasetsWithVolumesWithoutNullValues = sortedDatasetsWithVolumes.filter((dataset) => {
    return !(Array.isArray(dataset.values) && dataset.values.every((value) => value === null))
  }) // TODO: This check can be removed when this wrike is completed: https://app-eu.wrike.com/open.htm?id=1466970642. This is a temporary fix to avoid null values since hardcoded blockbids are still set in templateUiConfigs.ts. When the hardcoded blockbids are removed this check can be removed and datasetsWithVolumesWithoutNullValues should be replaced with sortedDatasetsWithVolumes.

  const worksheetDatasets = prepareDatasetsToExcelExportData(
    datasetsWithVolumesWithoutNullValues,
    datasetNames,
    `HH:mm`,
    true
  )
  const totalWorksheet = [].concat(worksheetDataInfo, worksheetDatasets)
  const addedFooter = [].concat(totalWorksheet, [[], [elplanSnap.excel_export_footer_text]])

  XLSX.utils.book_append_sheet(exportData, XLSX.utils.aoa_to_sheet(addedFooter))
  XLSX.writeFile(
    exportData,
    getChosenDateFileName(
      `xlsx`,
      Datetime.toLocalTime(date?.startTime ?? elplanSnap.date.start_time, 'onlyDate'),
      elplanSnap.excel_file_name
    ),
    { sheetStubs: true }
  )
}

function prepareDatasetsToExcelExportData(
  datasets: Dataset[],
  datasetNames: Record<string, string>,
  timeFormat?: string,
  showInterval?: boolean,
  show_times_in_utc?: boolean
): (string | number | undefined)[][] {
  const worksheet: (string | number | undefined)[][] = [[i18n.t(`Time`)]]
  const datasetNamesInOrder: string[] = []
  const worksheetObj: unknown = {}

  datasets.forEach((dataset) => {
    const name = dataset.title ?? datasetNames[dataset.return_id]

    if (!name) {
      return
    }

    if (dataset.times.length === 0) {
      return
    }

    datasetNamesInOrder.push(name) // name can be completely without data but must be in correct order

    dataset.times.forEach((time, index) => {
      const nextHour =
        index === dataset.times.length - 1 ? Datetime.getDayAfterDate(time).startTime : dataset.times[index + 1]
      const timeWithIntervalAndTimeFormat = showInterval
        ? `${moment(time).format(timeFormat)} - ${moment(nextHour).format(timeFormat)}`
        : moment(time).format(timeFormat)
      let currentKey = timeFormat !== undefined ? timeWithIntervalAndTimeFormat : time

      if (show_times_in_utc || time === i18n.t('Sum') || time === i18n.t('Average')) {
        currentKey = time
      }

      const currentValue = !isNaN(parseFloat(dataset.values[index])) ? dataset.values[index] : ``

      if (!worksheetObj[currentKey]) {
        worksheetObj[currentKey] = { [name]: isFinite(currentValue) ? currentValue : `` }
      } else {
        worksheetObj[currentKey][name] = isFinite(currentValue) ? currentValue : ``
      }
    })
  })

  //add all headers to first row of export before moving on
  worksheet[0].push(...datasetNamesInOrder)

  Object.entries(worksheetObj).forEach(([key, values]) => {
    const currentRow = [key]

    datasetNamesInOrder.forEach((datasetName) => {
      if (datasetName in values) {
        currentRow.push(values[datasetName])
      } else {
        currentRow.push(``)
      }
    })
    worksheet.push(currentRow)
  })

  return worksheet
}

export function fillMissingHours(
  datasets: Dataset[],
  startTime: ISODateTime,
  endTime: ISODateTime,
  forceFill?: boolean
): Dataset[] {
  const allHours = Datetime.getHoursBetween(startTime, endTime)

  return datasets.map((dataset) => {
    // Only one item in dataset means a aggregate-method is applied. We don't need to fill missing hours.
    // In some cases we want to fill out the dataset regardless to make sure it's the same length as the other datasets.
    if (dataset.values.length === 1 && !forceFill) {
      return dataset
    }

    // Entirely empty dataset
    if (!dataset.times.length) {
      dataset.times = allHours
      dataset.values = dataset.times.map(() => null)
      return dataset
    }

    // Fill missing hours between
    const hourToValueMap: Record<string, number | null> = {}

    dataset.times.forEach((time, index) => {
      hourToValueMap[time] = dataset.values[index]
    })

    dataset.times = allHours
    dataset.values = allHours.map((time) => hourToValueMap[time] ?? null)

    return dataset
  })
}

export function getReturnIdsFromDatasetInstruction(datasetInstruction: Partial<DatasetInstruction>): string[] {
  const returnIds: string[] = []

  if (datasetInstruction.return_id?.length && datasetInstruction.return_id?.length > 0) {
    returnIds.push(datasetInstruction.return_id)
  }

  if (datasetInstruction.contract && hasSources(datasetInstruction.contract)) {
    datasetInstruction.contract?.sources?.forEach(({ return_id }) => {
      returnIds.push(return_id)
    })
  }

  if (datasetInstruction.type === 'dbs') {
    const contract = datasetInstruction.contract as DatasetContractDbS
    let internalIdsList = contract.metadata_filter?.include?.internal_id ?? []
    if (typeof internalIdsList === 'string') {
      internalIdsList = [internalIdsList]
    }

    if (contract.id_renaming && isObject(contract.id_renaming)) {
      const renamedIds: string[] = []

      internalIdsList.forEach((id) => {
        if (contract.id_renaming?.[id] === undefined) {
          renamedIds.push(id)
        } else if (isArray(contract.id_renaming[id])) {
          renamedIds.push(...contract.id_renaming[id])
        } else if (typeof contract.id_renaming[id] === 'string') {
          renamedIds.push(contract.id_renaming[id])
        }
      })

      internalIdsList = renamedIds
    }

    const internalIds = new Set(internalIdsList)

    Object.entries(contract.id_renaming || {}).forEach(([internalId, returnId]) => {
      if (isArray(returnId)) {
        returnIds.push(...returnId)
      } else {
        returnIds.push(returnId)
      }

      // Skip renamed IDs
      if (internalIds.has(internalId)) {
        internalIds.delete(internalId)
      }
    })

    // Include remaining internal ids
    internalIds.forEach((internalId) => {
      returnIds.push(internalId)
    })
  }

  return returnIds
}

export function prefixAllReturnIds(
  _datasetInstruction: DatasetInstruction,
  prefix: string | number
): DatasetInstruction {
  const datasetInstruction = clone(_datasetInstruction)
  const { type } = datasetInstruction

  if (hasSources(datasetInstruction.contract)) {
    datasetInstruction.contract.sources =
      datasetInstruction.contract.sources?.map((source) => ({
        ...source,
        return_id: `${prefix}${source.return_id}`,
      })) ?? []
  }

  if (type === `calc` && datasetInstruction.contract?.variables) {
    (datasetInstruction.contract as DatasetContractCalc).variables = (
      datasetInstruction.contract as DatasetContractCalc
    ).variables.map((variable) => `${prefix}${variable}`)
  }

  if (datasetInstruction.return_id) {
    datasetInstruction.return_id = `${prefix}${datasetInstruction.return_id}`
  }

  return datasetInstruction
}

export function getReturnIdsFromDatasetsInstruction(datasetInstructions: DatasetInstruction[]): string[] {
  const returnIds: string[] = []

  datasetInstructions.forEach((datasetInstruction) => {
    returnIds.push(...getReturnIdsFromDatasetInstruction(datasetInstruction))
  })

  return returnIds
}

export function mergeDatasetsWithSameReturnId(datasets: Dataset[]): Dataset[] {
  const datasetCache = datasets.reduce(
    (acc, dataset) => {
      if (!acc[dataset.return_id]) {
        acc[dataset.return_id] = {
          status: dataset.status,
          error_msg: dataset.error_msg,
          decimal: dataset.decimal,
          timeValueMap: new Map(),
        }
      }
      const timeValueMap = acc[dataset.return_id].timeValueMap

      dataset.times.forEach((time, index) => {
        const isoDateTime = time as ISODateTime
        // Try to avoid null values when possible (add null as fallback to not introduce undefined into the map)
        timeValueMap.set(isoDateTime, dataset.values[index] ?? (timeValueMap.get(isoDateTime) || null))
      })

      return acc
    },
    {} as Record<
      string,
      Pick<Dataset, 'status' | 'error_msg' | 'decimal'> & {
        timeValueMap: Map<ISODateTime, number | null>
      }
    >
  )

  return Object.entries(datasetCache).map(([returnId, dataset]) => {
    const { status, error_msg, decimal, timeValueMap } = dataset
    const sortedTimeValEntries = Array.from(timeValueMap.entries()).sort(([timeA], [timeB]) =>
      Datetime.isAfter(timeA, timeB) ? 1 : -1
    )
    const times = sortedTimeValEntries.map(([time]) => time)
    const values = sortedTimeValEntries.map(([_time, value]) => value)

    return {
      status,
      error_msg,
      decimal,
      return_id: returnId,
      times,
      values,
    }
  })
}

/**
 * Add new missing return_ids from DSI as null-valued datasets.
 */
export function addMissingDatasets(datasets: Dataset[], instruction: DatasetInstruction): Dataset[] {
  const allExpectedReturnIds = Array.from(new Set(getReturnIdsFromDatasetInstruction(instruction)))
  if (!allExpectedReturnIds?.length) {
    return datasets
  }

  const allExistingIdsSet = new Set(datasets.map((dataset) => dataset.return_id))
  const additionalMissingIds = allExpectedReturnIds.filter((id) => !allExistingIdsSet.has(id))

  if (!additionalMissingIds.length) {
    return datasets
  }
  // eslint-disable-next-line no-console
  console.warn(
    `${additionalMissingIds.length} Additional missing internal_ids from DbS response. Filling datasets with null values.`,
    { additionalMissingIds, allExpectedReturnIds, allExistingIdsSet }
  )

  let allTimes: ISODateTime[] = []
  if (datasets.length > 0 && datasets[0].times?.length > 0) {
    const firstTime = datasets[0].times?.[0] as unknown as ISODateTime
    const lastTime = datasets[0]?.times?.[datasets[0]?.times?.length - 1] as unknown as ISODateTime
    allTimes = Datetime.getHoursBetween(firstTime, lastTime)
  }

  additionalMissingIds.forEach((returnId) => {
    datasets.push({
      times: allTimes,
      values: Array(allTimes.length).fill(null),
      return_id: returnId,
    })
  })

  return datasets
}

function applyOperator(a: number, b: number, operator: string): number {
  switch (operator) {
    case '+':
      return a + b
    case '-':
      return a - b
    case '*':
      return a * b
    case '/':
      if (b === 0) {
        throw new Error('Division by zero')
      }
      return a / b
    default:
      throw new Error('Invalid operator')
  }
}

//Only tested with operator '-'
export function calculateDatasetsWithSameReturnId(datasets: Dataset[], operator: string): Dataset[] {
  const datasetMap: Record<string, Dataset> = {}

  datasets.forEach((dataset) => {
    const returnId = dataset.return_id

    if (!datasetMap[returnId]) {
      datasetMap[returnId] = dataset
    } else {
      const savedDataset = datasetMap[returnId]
      const savedDatasetTimes = new Set<ISODateTime>(savedDataset.times as ISODateTime[])
      const unsavedDatasetTimes = new Set<ISODateTime>(dataset.times as ISODateTime[])

      const hasOverlap = [...savedDatasetTimes].some((time) => unsavedDatasetTimes.has(time))

      if (!hasOverlap) {
        datasetMap[returnId].values = [...savedDataset.values, ...dataset.values]
        datasetMap[returnId].times = [...savedDataset.times, ...dataset.times]
      } else {
        const savedDatasetStart = savedDataset.times[0]
        const unsavedDatasetStart = dataset.times[0]

        if (!savedDatasetStart || !unsavedDatasetStart) {
          datasetMap[returnId].values = [...savedDataset.values, ...dataset.values]
          datasetMap[returnId].times = [...savedDataset.times, ...dataset.times]
        } else if (Datetime.isAfter(savedDatasetStart, unsavedDatasetStart)) {
          datasetMap[returnId].values = [...dataset.values, ...savedDataset.values]
          datasetMap[returnId].times = [...dataset.times, ...savedDataset.times]
        } else {
          const joinedTimes = [...savedDataset.times, ...dataset.times] as ISODateTime[]
          const allTimes = Datetime.sortDatetimes(joinedTimes)
          const storedValueMap: Record<ISODateTime, number> = savedDataset.values.reduce(
            (acc, v, i) => ({
              ...acc,
              [savedDataset.times[i]]: v,
            }),
            {}
          )
          const valueMap: Record<ISODateTime, number> = dataset.values.reduce(
            (acc, v, i) => ({
              ...acc,
              [dataset.times[i]]: v,
            }),
            {}
          )

          const values = allTimes.map((t) => applyOperator(storedValueMap[t], valueMap[t], operator))

          datasetMap[returnId].values = values
          datasetMap[returnId].times = allTimes
        }
      }
    }
  })

  return Object.values(datasetMap)
}

export function applyZoomToDataset(
  dataset: Dataset,
  zoomStartTime: ISODateTime | null,
  zoomEndTime: ISODateTime | null,
  ignoreZoomIds?: string[]
): Dataset {
  return applyZoomToDatasets([dataset], zoomStartTime, zoomEndTime, ignoreZoomIds)[0]
}

export function applyZoomToDatasets(
  datasets: Dataset[],
  zoomStartTime: ISODateTime | null,
  zoomEndTime: ISODateTime | null,
  ignoreZoomIds?: string[]
): Dataset[] {
  if (!zoomStartTime || !zoomEndTime) {
    return datasets
  }

  const ignoreZoomIdsSet = new Set(ignoreZoomIds || [])
  const zoomStartTimeDate = zoomStartTime ? new Date(zoomStartTime) : null
  const zoomEndTimeDate = zoomEndTime ? new Date(zoomEndTime) : null

  return datasets.map((dataset) => {
    if (ignoreZoomIdsSet.has(dataset.return_id)) {
      return dataset
    }

    const shouldKeepDatapoint = dataset.times.map((time) => {
      const timeDate = new Date(time)

      const zoomStartIsBeforeOrEqual = zoomStartTimeDate ? zoomStartTimeDate <= timeDate : false
      const zoomEndIsAfterOrEqual = zoomEndTimeDate ? zoomEndTimeDate >= timeDate : false
      return zoomStartIsBeforeOrEqual && zoomEndIsAfterOrEqual
    })

    return {
      ...dataset,
      values: dataset.values.filter((_value, i) => shouldKeepDatapoint[i]),
      times: dataset.times.filter((_time, i) => shouldKeepDatapoint[i]),
    }
  })
}

export type ApplyAggregateOptions = {
  skipInvalidIndices?: boolean
  invalidIndexCheckIds?: string[]
}

export function datasetHasOnlyNullValues(dataset: Dataset): boolean {
  const nrOfValidIndices = dataset.values.filter((v) => v !== null).length
  return nrOfValidIndices === 0
}

export function applyAggregateFromUiConfig(
  datasets: Dataset[],
  uid: number,
  options?: ApplyAggregateOptions
): Dataset[] {
  const uiConfigSnap = snapshot(uiConfigStore)
  const filterSnap = snapshot(filterStore)
  const route = getRoute()
  const uiConfig = uiConfigSnap.getParsedUiConfig(uid) || { dataset_instructions: [] }

  let aggregatedDataset: Dataset[] = []
  const uiConfigHasDatasetAndInstructionThatDoesNotMatch =
    (uiConfig?.props?.include_items_in_grouping && filterSnap.groupingPerRoute[route]) ?? false //if the uiconfigs data has been manipulated by grouping, the original instructions will not match the datasets and we will therefore need to not care about the original instruction

  const returnIds = datasets.map((dataset) => dataset.return_id)
  const returnIdsSet = new Set(returnIds)

  const returnIdToDatasetInstructionMap: Record<string, DatasetInstruction> = uiConfig.dataset_instructions.reduce(
    (acc, datasetInstruction) => {
      getReturnIdsFromDatasetInstruction(datasetInstruction).forEach((id) => {
        acc = {
          ...acc,
          [id]: datasetInstruction,
        }
      })

      return acc
    },
    {}
  )

  datasets.forEach((dataset) => {
    if (uiConfigHasDatasetAndInstructionThatDoesNotMatch && !datasetHasOnlyNullValues(dataset)) {
      aggregatedDataset.push(dataset)
    }
    if (returnIdsSet.has(dataset.return_id) && !datasetHasOnlyNullValues(dataset)) {
      aggregatedDataset.push(dataset)
    }
  })

  const invalidValueIndeces: Set<number> = new Set()
  const invalidIndexCheckIds = new Set(options?.invalidIndexCheckIds || [])

  if (options?.skipInvalidIndices !== true) {
    aggregatedDataset.forEach((dataset) => {
      if (invalidIndexCheckIds.size && !invalidIndexCheckIds.has(dataset.return_id)) {
        return
      }

      dataset.values.forEach((value, i) => {
        if (value === null || value === undefined) {
          invalidValueIndeces.add(i)
        }
      })
    })
  }

  aggregatedDataset = aggregatedDataset
    .map((dataset) => {
      const instruction = uiConfigHasDatasetAndInstructionThatDoesNotMatch
        ? uiConfig.dataset_instructions[0]
        : returnIdToDatasetInstructionMap[dataset.return_id]

      if (instruction?.filter?.aggregate_in_frontend && instruction?.filter?.aggregate) {
        return aggregateDataset(clone(dataset), instruction.filter.aggregate, invalidValueIndeces)
      }

      return dataset
    })
    .filter((d) => !!d)
    .map((dataset) => limitDataset(dataset, returnIdToDatasetInstructionMap[dataset.return_id]?.filter || {}))
    .map((dataset) => setNanIfZero(dataset, returnIdToDatasetInstructionMap[dataset.return_id]?.filter || {}))

  return aggregatedDataset
}

export function applyAliasToDatasetInstruction(
  datasetInstruction: DatasetInstruction,
  alias: UiConfigAliases
): DatasetInstruction {
  if (!Object.keys(alias).length) {
    return datasetInstruction
  }

  return applyToNestedString<DatasetInstruction>(datasetInstruction, (str: string): string | number => {
    if (str.startsWith(`$`)) {
      const key = str.substring(1, str.length)
      return alias[key] ?? str
    }

    return str
  })
}

export function fillPeriodWithFirstIndexValue(datasetInstruction: DatasetInstruction, dataset: Dataset): Dataset {
  const earliestStartTime = getEarliestStartTime([datasetInstruction])
  const latestEndTime = getLatestStartTime([datasetInstruction])
  dataset.times = Datetime.getHoursBetween(earliestStartTime, latestEndTime)
  const filledDataset = fillMissingHours([dataset], earliestStartTime, latestEndTime)[0]
  filledDataset.values = filledDataset.times.map(() => dataset.values[0] ?? null)

  return filledDataset
}

export function serializeTableAccessor(accessor: string): string {
  return accessor.replace(/[^a-zA-Z0-9_]/g, `_`)
}

export function normalizeDatasetId(id: string): string {
  return id.replace(/[-._\s]/g, '').toLowerCase()
}

export function getAggregatedDatasets(
  datasets: Dataset[] | { return_id: string; values: (number | null)[]; decimal?: number }[],
  aggregate: 'Sum' | 'Average' | 'Max' | 'Min'
): Record<string, unknown>[] {
  const aggregateRecord: Record<string, unknown>[] = [{ time: i18n.t(aggregate) }]

  if (!datasets.find((datasetColumn) => datasetColumn.times?.includes(i18n.t(aggregate)))) {
    datasets.forEach((datasetColumn) => {
      aggregateRecord[0][serializeTableAccessor(datasetColumn.return_id)] = aggregatedDataset(
        datasetColumn,
        aggregate
      ).value
    })
  }
  return aggregateRecord
}

function aggregatedDataset(
  dataset: Dataset | { return_id: string; values: (number | null)[]; decimal?: number | undefined },
  aggregate: 'Sum' | 'Average' | 'Max' | 'Min'
): { returnId: string; value: number | string } {
  const nonNullValues = dataset.values.filter((value): value is number => value !== null && value !== '-')

  let value: number | string = 0
  if (aggregate === 'Sum' || aggregate === 'Average') {
    value = nonNullValues.reduce((sum, value) => sum + value, 0)
    value = nonNullValues.length > 0 ? (aggregate === 'Average' ? value / nonNullValues.length : value) : '-'
  } else if (aggregate === 'Max') {
    value = nonNullValues.length > 0 ? Math.max(...nonNullValues) : '-'
  } else if (aggregate === 'Min') {
    value = nonNullValues.length > 0 ? Math.min(...nonNullValues) : '-'
  }

  return { returnId: dataset.return_id, value: isNumber(value) ? round(value, dataset.decimal ?? 2) : `-` }
}
