import * as Sentry from '@sentry/browser'


const DO_NOT_LOG_DOMAINS = ['stockholmexergi.se']

export default {
  isInitialized: false,
  init: function (): void {
    if (this.isInitialized) {
      return
    }
    this.isInitialized = true
    Sentry.init({
      dsn: 'https://0dd8348bae2987899e10e243f5da2bba@o4508211764068352.ingest.de.sentry.io/4508212151124048',
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration(), Sentry.replayCanvasIntegration(), Sentry.captureConsoleIntegration({levels: ['error']})],
      environment: process.env.NODE_ENV,
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/api\.aurorabysigholm\.se\/api/,
        /^https:\/\/api-staging\.aurorabysigholm\.se\/api/,
      ],
      maxValueLength: 10000, // Increase max JSON value length to 1000
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      enabled: process.env.NODE_ENV !== 'development',
    })
  },
  captureException: function (err: Error): void {
    Sentry.captureException(err)
  },
  captureMessage: function (message: string): void {
    Sentry.captureMessage(message)
  },
  setUser: function (user: User): void {

    // Disable email and user logging for domains that are in the do not log list    
    if (user && DO_NOT_LOG_DOMAINS.includes(user.email.split('@')[1])) {
      return
    }

    Sentry.setUser(user)
    Sentry.setTag('user_id',  user?.id)
  },
  setActiveSystem: function (system: System): void {
    Sentry.setTag('system', system.name)
    Sentry.setTag('organization', system.organization_name)
    Sentry.setTag('system_id', system.id)
    Sentry.setTag('organization_id', system.organization)
  },
}
