import { useCallback } from 'react'

import { useChannel, useEvent } from '@harelpls/use-pusher'
import {
  DATASET_FOR_UI_CONFIG_REFRESH_FOLLOW_UP,
  DATASET_FOR_UI_CONFIG_REFRESH_ON_REAL_TIME_OPTIMIZE,
  getSandboxProjectUiConfigRefreshToken,
  reFetchAllDatasetsOnOptimize,
  reFetchAllDatasetsOnFuelPlan,
} from 'api/dataset/dataset.api'
import { GET_DIGITAL_TWIN_ACCUMULATOR_INFO_QUERY_KEY } from 'api/digitalTwin/digitalTwin.api'
import { OPERATIONAL_EVENT_QUERY_KEY } from 'api/events/events.api'
import { NOTIFICATIONS_QUERY_KEY } from 'api/notifications/notifications.api'
import { OptJobStatus, OptJobType, OPT_JOBS_QUERY_KEY } from 'api/optJobs/optJobs.api'
import { OPT_PROJECTS_QUERY_KEY, setOptProjectsQueryData } from 'api/optProjects/optProjects.api'
import { SERIES_GROUP_DATA_QUERY_KEY } from 'api/seriesGroups/seriesGroups.api'
import { SYSTEM_QUERY_KEY } from 'api/systems/systems.api'
import { UNITS_QUERY_KEY } from 'api/units/units.api'
import { useUser } from 'api/users/users.api'
import { useTranslation } from 'react-i18next'
import authStore from 'store/auth/auth'
import { useAlert } from 'ui/components/AlertContext/AlertContext'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { SubtypeObject } from 'ui/components/NotificationCenter/NotificationCenter.helper'

import { queryClient } from 'helpers/queryClient'

const alertProps = {
  anchorOrigin: { vertical: `top` as const, horizontal: `right` as const },
  autoHideDuration: 6000,
}
const onOptModelHasUnusedSettingsPusherEvent = (
  data:
    | {
        opt_model_id: number
        system: number
      }
    | undefined
): void => {
  if (data) {
    queryClient.invalidateQueries([SYSTEM_QUERY_KEY, data.system])
  }
}

export default function Initialization(): null {
  const { systemId, activeSystem, setActiveSystem } = useAuth()
  const { data: user } = useUser()
  const { info, success, error } = useAlert()
  const { t } = useTranslation()

  const optimizationsChannel = useChannel(activeSystem ? `aurora-django-optimizations-${activeSystem.name}` : undefined)
  useEvent(optimizationsChannel, 'opt-model-has-unused-settings', onOptModelHasUnusedSettingsPusherEvent)

  const systemChannel = useChannel(activeSystem ? `aurora-django-system-${activeSystem.id}` : undefined)
  useEvent(
    systemChannel,
    'primary_model_updated',
    (data?: { primary_opt_model?: PrimaryOptModel; primary_digital_twin?: PrimaryDigitalTwin }) => {
      if (activeSystem && data) {
        const newSystem = activeSystem
        newSystem.primary_opt_model = data?.primary_opt_model
        newSystem.primary_digital_twin = data?.primary_digital_twin
        setActiveSystem(newSystem)
        authStore.activeSystem = newSystem
      }
    }
  )

  const handleAutomaticOptimizationPusherEvent = useCallback(
    (data: { system: number; status: boolean } | undefined): void => {
      if (data) {
        queryClient.setQueryData<System | undefined>([SYSTEM_QUERY_KEY, data.system], (old) => {
          if (old) {
            data.status
              ? info(t(`Automatic optimization activated! Optimizations will be updated once every hour.`))
              : info(
                t(
                  `Automatic optimization deactivated! Optimizations will now need to be executed manually in order for the production plan to update.`
                )
              )
            return {
              ...old,
              automatic_optimization: data.status,
            }
          }
          return old
        })
      }
    },
    [info, t]
  )
  useEvent(optimizationsChannel, `automatic-opt-state-changed`, handleAutomaticOptimizationPusherEvent)

  const handleAccumulatorLevelsUpdated = useCallback(
    (data: { organization: number; system: number; opt_model: number } | undefined): void => {
      if (data) {
        const { opt_model } = data
        queryClient.invalidateQueries([UNITS_QUERY_KEY, { optModel: opt_model, unit_type: `Storage` }])
        info(t(`Measured values have been updated`), alertProps)
      }
    },
    [info, t]
  )
  useEvent(optimizationsChannel, `ack-levels-updated`, handleAccumulatorLevelsUpdated)

  const optimizationUpdatedPusherEvent = useCallback(
    (
      data:
        | {
            organization: number
            opt_job_type: OptJobType
            system: number
            opt_model: number
            status: OptJobStatus
            created_by: number
            opt_project: number
            subtypes?: SubtypeObject[]
          }
        | undefined
    ): void => {
      if (data) {
        const { opt_project, opt_job_type: type, status, created_by, system } = data
        queryClient.invalidateQueries([OPT_JOBS_QUERY_KEY, `latest`, type, 'Any', system])

        if (opt_project !== undefined) {
          queryClient.invalidateQueries([OPT_JOBS_QUERY_KEY, `latest`, type, 'Any', opt_project, system])
          if (status === `Finished`) {
            queryClient.invalidateQueries([OPT_JOBS_QUERY_KEY, `latest`, type, status, opt_project, system])
            queryClient.invalidateQueries(OPT_PROJECTS_QUERY_KEY)
            queryClient.invalidateQueries([getSandboxProjectUiConfigRefreshToken(opt_project)])
          } else {
            setOptProjectsQueryData({ projectId: opt_project, system, status })
          }
        }

        if (type === `measvalues_calculations` || type === `regular` || type === 'fuel_plan') {
          if (status === `Finished`) {
            if (type === 'fuel_plan') {
              reFetchAllDatasetsOnFuelPlan()
            } else {
              // Reload digital twin accumulator levels. We want to do this every time we get new meas values, but this is the best place to do it for now.
              // It will trigger when measvalues_calculation or regular optimization is finished.
              queryClient.invalidateQueries(GET_DIGITAL_TWIN_ACCUMULATOR_INFO_QUERY_KEY)

              reFetchAllDatasetsOnOptimize()
            }
            // To get new data
            queryClient.invalidateQueries([
              SERIES_GROUP_DATA_QUERY_KEY,
              {
                groupType: `PROD_REG`,
              },
            ])
          }
          if (type === `regular` || type === 'fuel_plan') {
            if (status === `Finished`) {
              // To reset opt_model_has_unused_settings
              queryClient.invalidateQueries([SYSTEM_QUERY_KEY, data.system])
              queryClient.invalidateQueries([OPT_JOBS_QUERY_KEY, 'latest', type, status, system])
              // Limit 2, for all status. This query is used when comparing the latest 2 finished regular opt jobs
              queryClient.invalidateQueries([OPT_JOBS_QUERY_KEY, 'latest', type, 2, 'Any', system])
              if (data.subtypes) {
                const subtypeNames = data.subtypes.map((subtype) => subtype.display_name).join(' ,')
                success(
                  t(`Production plan optimization finished for subtypes: {{subtypeNames}}`, {
                    subtypeNames: subtypeNames,
                  }),
                  alertProps
                )
              }
              if (user && created_by !== user.id) {
                success(
                  t(`A new production plan optimization just finished! The new result is presented under 'Production plan'`),
                  alertProps
                )
              } else if (created_by === null) {
                success(t(`An automatic optimization finished just now`), alertProps)
              } else {
                success(t(`Optimization successful`), alertProps)
              }
            } else if (status === `Failed`) {
              error(t(`Optimization failed`), alertProps)
              if (data.subtypes) {
                const subtypeNames = data.subtypes.map((subtype) => subtype.display_name).join(' ,')
                error(
                  t(`Production plan optimization failed for subtypes: {{subtypeNames}}`, {
                    subtypeNames: subtypeNames,
                  }),
                  alertProps
                )
              }
            } else if (status === `Unsolvable`) {
              error(t(`No optimal solution could be found. Check current model settings and try again.`), alertProps)
            }
          }
        } else if (type === `sandbox` && created_by === user?.id) {
          if (status === `Finished`) {
            success(t(`Sandbox optimization successful`), alertProps)
          } else if (status === `Failed`) {
            error(t(`Sandbox optimization failed`), alertProps)
          } else if (status === `Unsolvable`) {
            error(
              t(`No optimal solution could be found in sandbox optimization. Check your settings and try again.`),
              alertProps
            )
          }
        } else if (type === 'real_time') {
          if (status === 'Finished') {
            queryClient.invalidateQueries([DATASET_FOR_UI_CONFIG_REFRESH_ON_REAL_TIME_OPTIMIZE])
          }
        } else if (type === 'real_time_measvalues_calculations') {
          if (status === 'Finished') {
            queryClient.invalidateQueries([DATASET_FOR_UI_CONFIG_REFRESH_ON_REAL_TIME_OPTIMIZE])
          }
        } else if (type === 'followup_without_deviations') {
          if (status === `Finished`) {
            queryClient.invalidateQueries([DATASET_FOR_UI_CONFIG_REFRESH_FOLLOW_UP])
            success(t(`Followup optimization successful`), alertProps)
            if (data.subtypes) {
              const subtypeNames = data.subtypes.map((subtype) => subtype.display_name).join(' ,')
              success(
                t(`Followup optimization finished for subtypes: {{subtypeNames}}`, { subtypeNames: subtypeNames }),
                alertProps
              )
            }
          } else if (status === `Failed`) {
            error(t(`Followup optimization failed`), alertProps)
            if (data.subtypes) {
              const subtypeNames = data.subtypes.map((subtype) => subtype.display_name).join(' ,')
              error(
                t(`Followup optimization failed for subtypes: {{subtypeNames}}`, { subtypeNames: subtypeNames }),
                alertProps
              )
            }
          } else if (status === `Unsolvable`) {
            error(t(`No optimal solution could be found. Check current model settings and try again.`), alertProps)
          }
        }
      }
    },
    [error, success, t, user]
  )
  useEvent(optimizationsChannel, `optimization-updated`, optimizationUpdatedPusherEvent)

  // Pusher - Notification Center
  const notificationChannel = useChannel(systemId ? `notification-center-${systemId}` : undefined)
  useEvent(notificationChannel, `notification-create`, () => {
    queryClient.invalidateQueries(NOTIFICATIONS_QUERY_KEY)
  })
  useEvent(notificationChannel, `notification-update`, () => {
    queryClient.invalidateQueries(NOTIFICATIONS_QUERY_KEY)
  })
  useEvent(notificationChannel, `notification-delete`, () => {
    queryClient.invalidateQueries(NOTIFICATIONS_QUERY_KEY)
  })

  // Pusher - Operational Events
  const operationalEventChannel = useChannel(
    activeSystem?.primary_digital_twin ? `operational-events-${activeSystem?.primary_digital_twin.id}` : undefined
  )
  useEvent(operationalEventChannel, `operational_event_created`, () => {
    queryClient.invalidateQueries(OPERATIONAL_EVENT_QUERY_KEY)
  })
  useEvent(operationalEventChannel, `operational_event_updated`, () => {
    queryClient.invalidateQueries(OPERATIONAL_EVENT_QUERY_KEY)
  })
  useEvent(operationalEventChannel, `operational_event_deleted`, () => {
    queryClient.invalidateQueries(OPERATIONAL_EVENT_QUERY_KEY)
  })

  return null
}
