import { apiClient } from 'api/apiClient/apiClient'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import elplanStore, { setElplan, setElplanInfoStore } from 'store/elplan/elplan'
import { useAlert } from 'ui/components/AlertContext/AlertContext'
import sentry from 'utils/sentry/sentry'
import { useSnapshot } from 'valtio'

export const ELPLAN_BID_REFRESH_EVENT_QUERY_KEY = 'elplan_bid_refresh_token'

export type BidType = {
  type: 'blockbid' | 'profilebid' | 'spotbid_without_price'
  amount: number
  minimum_hours_for_bid: number
}

type BlockBidProps = {
  price: number
  block_order: number
}

type SpotBidWithPriceProps = {
  price: number
}

type BidBase = {
  id: number
  elplan_info: number
  type: 'blockbid' | 'spotbid_without_price' | 'spotbid_with_price' | 'profilebid'
  name: string
  start_time: ISODateTime
  end_time: ISODateTime
  created_at: ISODateTime
  updated_at: ISODateTime
  volume: number
  is_locked?: boolean
  props: BlockBidProps | SpotBidWithPriceProps | Record<string, never>
}

export type BlockBid = {
  type: 'blockbid'
  props: BlockBidProps
} & BidBase

export type SpotBidWithoutPrice = {
  type: 'spotbid_without_price'
  props: Record<string, never>
} & BidBase

export type SpotBidWithPrice = {
  type: 'spotbid_with_price'
  props: SpotBidWithPriceProps
} & BidBase

export type ProfileBid = {
  type: 'profilebid'
  props: Record<string, never>
} & BidBase

export type Bid = BlockBid | SpotBidWithoutPrice | SpotBidWithPrice | ProfileBid

export type PlanValues = {
  volume: number
  time?: ISODateTime
  start_time: ISODateTime
  end_time: ISODateTime
  id?: number | null
}

export type Elplan = {
  id: number
  elplan_info: number
  date: ISODateTime
  is_locked: boolean
  elplan_values: PlanValues[]
  locked_at: ISODateTime
  created_at: ISODateTime
  updated_at: ISODateTime
  created_by: ISODateTime
  locked_by: string
  da_results?: PlanValues[]
}

export type CreateElplanBody = {
  elplan_info: number
  date: string
  values: {
    time?: ISODateTime
    volume?: number
  }[]
}

export type UpdateElplanBody = {
  id: number
  values: {
    id: number
    volume?: number
  }[]
}

export type UpdateElplanBidsBody = {
  elplan_info: number,
  bids: { volume?: number, id?: number | null }[]
}

export type ElplanInfo = {
  id: number
  elplan_info_id: number
  date: {
    start_time?: ISODateTime
    end_time?: ISODateTime
  }
  deadline_time?: `${number}:${number}:${number}`
  broker_name: string
  excel_file_name: string
  currency: string
  bid_types: BidType[]
  integration: string | undefined | null
  maxBidVolume?: number | undefined | null
  only_positive_bids?: boolean
  excel_export_footer_text?: string
}

export type BidDataType = 'blockbid' | 'spotbid_without_price' | 'spotbid_with_price' | 'profilebid'
export type CreateBidData = {
  elplan_info: number
  type: BidDataType
  start_time: ISODateTime
  end_time: ISODateTime
  volume?: number | number[]
  price?: number
  block_order?: number
  name?: string
}

export type UpdateBidData = {
  id: number
  start_time: ISODateTime
  end_time: ISODateTime
  volume?: number | number[]
  price?: number
  name?: string
}

export type DeleteBidData = {
  id: number
}

export const ELPLAN_REFRESH_EVENT_QUERY_KEY = 'elplan_refresh_token'
// LAGD ELPLAN
export function useElplan(elplanInfoId: number, date: ISODateTime | undefined) {
  const params = {
    elplan_info: elplanInfoId,
    date: date,
  }

  return useQuery([ELPLAN_REFRESH_EVENT_QUERY_KEY, params], async () => {
    if (typeof elplanInfoId === 'undefined' 
      || elplanInfoId == 0 
      || typeof date === 'undefined') {
      return []
    }

    const result = await apiClient<Elplan>(`elplan`, { params }).catch(() => setElplan(0, false, false, [], date, []))

    if (result) {
      setElplan(result.id, result.is_locked, true, result.elplan_values, date, result.da_results)
    } else {
      setElplan(0, false, false, [], date, [])
    }

    return result
  })
}

export function useCreateElplanMutation() {
  const queryClient = useQueryClient()
  const { error } = useAlert()
  return useMutation((data: CreateElplanBody) => apiClient(`elplan/create`, { method: `POST`, data }), {
    onSuccess: () => {
      queryClient.invalidateQueries(ELPLAN_REFRESH_EVENT_QUERY_KEY)
    },
    onError: (errors) => {
      const errorMessages = errors as { non_field_errors?: string[] }
      const nonFieldErrors = errorMessages.non_field_errors ?? []
      if (nonFieldErrors) {
        error(nonFieldErrors[0])
      }
    },
  })
}

export function useUpdateElplanMutation() {
  const queryClient = useQueryClient()
  const { error } = useAlert()
  return useMutation((data: UpdateElplanBody) => apiClient(`elplan/${data.id}/update`, { method: `PUT`, data }), {
    onSuccess: () => {
      queryClient.invalidateQueries(ELPLAN_REFRESH_EVENT_QUERY_KEY)
    },
    onError: (errors) => {
      const errorMessages = errors as { non_field_errors?: string[] }
      const nonFieldErrors = errorMessages.non_field_errors ?? []
      if (nonFieldErrors) {
        error(nonFieldErrors[0])
      }
    },
  })
}

export function useToggleLockMutation() {
  const queryClient = useQueryClient()
  const { error } = useAlert()
  return useMutation((data: { id: number }) => apiClient(`elplan/${data.id}/toggle_lock`, { method: `PATCH` }), {
    onSuccess: () => {
      queryClient.invalidateQueries(ELPLAN_REFRESH_EVENT_QUERY_KEY)
    },
    onError: (errors) => {
      const errorMessages = errors as { non_field_errors?: string[] }
      const nonFieldErrors = errorMessages.non_field_errors ?? []
      if (nonFieldErrors) {
        error(nonFieldErrors[0])
      }
    },
  })
}

//BID MANAGEMENT
export function useElplanInfo(systemId: number | undefined, enabled?: boolean) {
  const params = { system_id: systemId }

  return useQuery(
    [ELPLAN_BID_REFRESH_EVENT_QUERY_KEY, params],
    async (): Promise<ElplanInfo | undefined> => {
      if (systemId === undefined || systemId === null) {
        return
      }

      const result = await apiClient<ElplanInfo>(`elplan_info`, { params })

      setElplanInfoStore(
        result.deadline_time,
        result.broker_name,
        result.currency,
        result.bid_types,
        result.excel_file_name,
        result.id,
        result.excel_export_footer_text,
        result.integration,
        result.maxBidVolume,
        result.only_positive_bids
      )
      return result
    },
    {
      enabled: enabled ?? true,
    }
  )
}

export function useElplanBids(
  elplanInfoId: number | undefined, 
  startTime: ISODateTime | undefined, 
  endTime: ISODateTime | undefined
) {
  const params = {
    elplan_info: elplanInfoId,
    start_time: startTime,
    end_time: endTime,
  }

  return useQuery([ELPLAN_BID_REFRESH_EVENT_QUERY_KEY, params], async () => {
    if (elplanInfoId === 0 
      || typeof startTime === 'undefined' 
      || typeof endTime === 'undefined'
    ) {
      return []
    }
    
    const data = await apiClient<Bid[]>(`elplan_bids`, { params })
    return data
  })
}

export function useElplanBidUpdateMutation() {
  const queryClient = useQueryClient()
  const { error } = useAlert()
  return useMutation((data: UpdateBidData) => apiClient(`elplan_bid/${data.id}/update`, { method: `PUT`, data }), {
    onSuccess: () => {
      queryClient.invalidateQueries(ELPLAN_BID_REFRESH_EVENT_QUERY_KEY)
    },
    onError: (errors) => {
      const errorMessages = errors as { non_field_errors?: string[] }
      const nonFieldErrors = errorMessages.non_field_errors ?? []
      if (nonFieldErrors) {
        error(nonFieldErrors[0])
      }
    },
  })
}

export function useElplanBidCreateMutation() {
  const queryClient = useQueryClient()
  const { error } = useAlert()
  return useMutation((data: CreateBidData) => apiClient(`elplan_bid`, { method: `POST`, data }), {
    onSuccess: () => {
      queryClient.invalidateQueries(ELPLAN_BID_REFRESH_EVENT_QUERY_KEY)
    },
    onError: (errors) => {
      const errorMessages = errors as { non_field_errors?: string[] }
      const nonFieldErrors = errorMessages.non_field_errors ?? []
      if (nonFieldErrors) {
        error(nonFieldErrors[0])
      }
    },
  })
}

export function useElplanBidDeleteMutation() {
  const queryClient = useQueryClient()
  const { error } = useAlert()
  return useMutation((data: DeleteBidData) => apiClient(`elplan_bid/${data.id}/delete`, { method: `DELETE`, data }), {
    onSuccess: () => {
      queryClient.invalidateQueries(ELPLAN_BID_REFRESH_EVENT_QUERY_KEY)
    },
    onError: (errors) => {
      const errorMessages = errors as { non_field_errors?: string[] }
      const nonFieldErrors = errorMessages.non_field_errors ?? []
      if (nonFieldErrors) {
        error(nonFieldErrors[0])
      }
    },
  })
}

export function useCreateElplanBidsMutation() {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { success, error } = useAlert()
  return useMutation(
    (data: Record<string, unknown>) => apiClient<Bid[]>(`elplan_bid/create_elplan_bids`, { method: `POST`, data }),
    {
      onSuccess: (createdBids: Bid[]) => {
        queryClient.invalidateQueries(ELPLAN_BID_REFRESH_EVENT_QUERY_KEY)
        if (createdBids[0]) {
          const type =
            createdBids[0].type === 'blockbid'
              ? t('block bid')
              : createdBids[0].type === 'spotbid_without_price'
                ? t('spot bid')
                : t('bid')
          success(`${t('Successfully created')} ${type}`)
        }
      },
      onError: (errors) => {
        const errorMessages = errors as { non_field_errors?: string[] }
        const nonFieldErrors = errorMessages.non_field_errors ?? []
        if (nonFieldErrors) {
          error(nonFieldErrors[0])
        }
      },
    }
  )
}

export function useDeleteElplanBidsMutation() {
  const queryClient = useQueryClient()
  const { error } = useAlert()
  return useMutation(
    (data: Record<string, unknown>) => apiClient(`elplan_bid/delete_elplan_bid`, { method: `DELETE`, data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ELPLAN_BID_REFRESH_EVENT_QUERY_KEY)
      },
      onError: (errors) => {
        const errorMessages = errors as { non_field_errors?: string[] }
        const nonFieldErrors = errorMessages.non_field_errors ?? []
        if (nonFieldErrors) {
          error(nonFieldErrors[0])
        }
      },
    }
  )
}

export function useUpdateElplanBidsMutation() {
  const queryClient = useQueryClient()
  const { error } = useAlert()
  return useMutation((data: UpdateElplanBidsBody) => apiClient(`elplan_bid/update_elplan_bids`, { method: `PUT`, data }), {
    onSuccess: () => {
      queryClient.invalidateQueries(ELPLAN_BID_REFRESH_EVENT_QUERY_KEY)
    },
    onError: (errors) => {
      const errorMessages = errors as { non_field_errors?: string[] }
      const nonFieldErrors = errorMessages.non_field_errors ?? []
      if (nonFieldErrors) {
        error(nonFieldErrors[0])
      }
    },
  })
}

export function useCopyBidsFromDate() {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { success, error } = useAlert()
  return useMutation(
    (data: { elplan_info: number; from_date: ISODateTime; to_date: ISODateTime; type: string }) =>
      apiClient(`elplan_bid/copy_bids_from_date`, { method: `POST`, data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ELPLAN_BID_REFRESH_EVENT_QUERY_KEY)
        success(t('Bids copied successfully'))
      },
      onError: (errors, _variables) => {
        const errorMessages = errors as string
        if (errorMessages === 'DstError') {
          error(t('Could not copy bids - date is in DST change.'))
        } else if (errorMessages === 'NoBidsFoundError') {
          error(
            t('No bids found for {{chosenDate}} - cannot copy bids', { chosenDate: _variables.from_date as string })
          )
        } else {
          sentry.captureMessage(errorMessages)
        }
      },
    }
  )
}

export function useDeleteElplanBidsForDateMutation() {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { success, error } = useAlert()

  return useMutation(
    (data: { elplan_info: number; date: ISODateTime; type: string }) =>
      apiClient(`elplan_bid/delete_bids_for_date`, { method: `POST`, data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ELPLAN_BID_REFRESH_EVENT_QUERY_KEY)
        success(t('Successfully deleted bids'))
      },
      onError: (errors) => {
        const errorMessages = errors as { non_field_errors?: string[] }
        const nonFieldErrors = errorMessages.non_field_errors ?? []
        if (nonFieldErrors) {
          error(nonFieldErrors[0])
        }
      },
    }
  )
}

export function useElplanSendingBidMutation() {
  const { t } = useTranslation()
  const elplanSnap = useSnapshot(elplanStore)
  const queryClient = useQueryClient()
  const { success, error } = useAlert()

  return useMutation(
    (data: { date: string; system: string }) =>
      apiClient(`elplan_bids/place`, { method: `POST`, data, params: { date: data.date, system: data.system } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ELPLAN_BID_REFRESH_EVENT_QUERY_KEY)
        success(t('Bids sent successfully'))
      },
      onError: (errors) => {
        const errorMessages = errors as string
        if (errorMessages.includes('Bad Request: Day ahead productionplan was sent to late.')) {
          error(
            t('Day ahead productionplan was sent to late. Latest time for production plan is {{deadline_time}}', {
              deadline_time: elplanSnap.brokerDeadlineTime,
            })
          )
        } else if (errorMessages.includes('No bids found for the given date.')) {
          error(t('No bids found for the given date.'))
        } else {
          error(t('Something went wrong, try again or contact us.'))
        }
      },
    }
  )
}

export function useSentBidsToBroker(  
  elplanInfoId: number | undefined, 
  startTime: ISODateTime | undefined, 
  endTime: ISODateTime | undefined,  
  type: string | undefined  
) {  
  const params = {  
    elplan_info: elplanInfoId,  
    start_time: startTime,  
    end_time: endTime,  
    type: type ?? undefined,  
  }  
  return useQuery([ELPLAN_REFRESH_EVENT_QUERY_KEY, params], async (): Promise<{ latest_date: ISODateTime } | null> => {  
    const data = await apiClient<{ latest_date: ISODateTime } >(`elplan_bids/sent_to_broker_latest_date`, { params })  
    return data  
  }, {  
    enabled: (elplanInfoId !== undefined && elplanInfoId !== 0) && startTime !== undefined && endTime !== undefined,  
  })  
} 

export function useElplanSyncResultsMutation() {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { success, error } = useAlert()

  return useMutation(
    (data: { date: string; system: string }) =>
      apiClient<Elplan>(`elplan/sync_results`, {
        method: `POST`,
        data,
        params: { date: data.date, system: data.system },
      }),
    {
      onSuccess: (result: Elplan) => {
        queryClient.invalidateQueries(ELPLAN_REFRESH_EVENT_QUERY_KEY)
        success(t('Result from electricity supplier synced successfully'))
        setElplan(result.id, result.is_locked, true, result.elplan_values, result.date)
      },
      onError: (errors) => {
        const errorMessages = errors as string
        if (errorMessages.includes('Results not available for')) {
          error(t('Results not available for given date'))
        } else {
          error(t('Something went wrong, try again or contact us.'))
        }
      },
    }
  )
}
