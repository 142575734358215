import { updateOptimizeHourRange } from 'api/dataset/dataset.api'
import { getDefaultTimes, setZoom } from 'store/zoom/zoom'

const HOUR_IN_MILLISECONDS = 60 * 60 * 1000

// Feature flag to enable/disable the scheduler for dataset refetch every hour
// TODO: Remove this flag until we find a solution that could help update the dataset,
// without re-render the whole UI Configs
const ENABLE_DATASET_REFRESH_EVERY_HOUR_SCHEDULER = false
const ENABLE_OPTIMIZE_ZOOM_RESET_EVERY_HOUR_SCHEDULER = true

let datasetScheduler: NodeJS.Timeout | null = null
function datasetRefetchEveryHourScheduler(): void {
  if (!ENABLE_DATASET_REFRESH_EVERY_HOUR_SCHEDULER) {
    return
  }

  if (datasetScheduler) {
    clearInterval(datasetScheduler)
  }

  const now = new Date()
  const nextHour = new Date(now)
  nextHour.setHours(now.getHours() + 1, 0, 5, 0) // Whole hour plus 5 seconds

  const timeDiff = nextHour.getTime() - now.getTime()

  setTimeout(() => {
    updateOptimizeHourRange()

    datasetScheduler = setInterval(updateOptimizeHourRange, HOUR_IN_MILLISECONDS)
  }, timeDiff)
}

let zoomScheduler: NodeJS.Timeout | null = null
function resetOptimizeZoomEveryHourScheduler(): void {
  if (!ENABLE_OPTIMIZE_ZOOM_RESET_EVERY_HOUR_SCHEDULER) {
    return
  }

  if (zoomScheduler) {
    clearInterval(zoomScheduler)
  }

  function newHourHandler(): void {
    // Only reset zoom on optimize view on new hour
    const { startTime, endTime } = getDefaultTimes('optimize')
    setZoom(startTime, endTime, 'optimize')
  }

  const now = new Date()
  const nextHour = new Date(now)
  nextHour.setHours(now.getHours() + 1, 0, 1, 0) // Whole hour plus 1 seconds

  const timeDiff = nextHour.getTime() - now.getTime()

  setTimeout(() => {
    newHourHandler()

    zoomScheduler = setInterval(newHourHandler, HOUR_IN_MILLISECONDS)
  }, timeDiff)
}

export default function init(): void {
  datasetRefetchEveryHourScheduler()
  resetOptimizeZoomEveryHourScheduler()
}
